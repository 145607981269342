import Poll from "../models/poll";
import {hide, hideAll, isTokenValid, show} from "../scripts/utils";
import POLL_GET_QUERY from "../graphql/pollGet";
import POLL_UPDATE_MUTATION from "../graphql/pollUpdate";
import POLL_PUBLISH_MUTATION from "../graphql/pollPublish";
import PollAnswer from "../models/pollAnswer";
import FileResourceHelper from "../scripts/fileResourceHelper";
import GraphqlRepository from "../scripts/graphqlRepository";
import configs from "../scripts/configs";
import NOTIFICATION_EVENTS_SUBSCRIPTION from "../graphql/notificationEventsSubscription";
import GENERIC_NOTIFICATION_EVENTS_SUBSCRIPTION from "../graphql/genericNotificationEventsSubscription";
import {OperationResult} from "@urql/core";
import GenericEvent from "../models/genericEvent";
import DateTimeHelper from "../scripts/dateTimeHelper";
import TriggerNotificationGenericEvent from "../graphql/triggerNotificationGenericEvent";
import NotificationEventType from "../models/notificationEventType";
import {ActiveView} from "../models/activeView";
import SetupController from "./setupController";
import ViewParameters from "../scripts/types/viewParameters";
import {format, getUnixTime} from "date-fns";
import Base from "../models/base";
import AUTO_RESET_FEATURE_QUERY from "../graphql/autoResetPollFeatureGet";

export default class PollController {
  private now: Date;
  private poll: Poll | undefined;
  private readonly token: string;
  private readonly sessionUid: string;
  private readonly pollUid: string;
  private readonly showRealtimeAnswersChecked: boolean | undefined;
  private readonly action: string = "open";
  private readonly closeIn: string;
  private activeView: ActiveView;
  private currentSlide: number;
  private gqlRepository: GraphqlRepository;
  private notificationRepository: GraphqlRepository;

  private timer: number;
  private presentationTitle: string;
  private presentationSubtitle: string;
  private showQRCode: boolean;
  private qrCodeUrl: string;
  private countdownEnabled: boolean;

  constructor(viewParameters: ViewParameters) {
    const {
      token,
      sessionUid,
      pollUid,
      action,
      showLiveResults,
      activeView,
      slideNum,
      presentationTitle,
      presentationSubtitle,
      showQRCode,
      qrCodeUrl,
      countdownEnabled,
    } = viewParameters;

    this.token = token;
    this.sessionUid = sessionUid;
    this.pollUid = pollUid || "";
    this.action = action || "open";
    this.showRealtimeAnswersChecked = showLiveResults;
    this.closeIn =
      viewParameters.closeInValue && viewParameters.closeInUnit
        ? `${viewParameters.closeInValue}${viewParameters.closeInUnit}`
        : "";
    this.activeView = activeView;
    this.currentSlide = slideNum;
    this.presentationTitle = presentationTitle;
    this.presentationSubtitle = presentationSubtitle;
    this.showQRCode = showQRCode;
    this.qrCodeUrl = qrCodeUrl;
    this.countdownEnabled = countdownEnabled;

    this.now = new Date();
    this.gqlRepository = new GraphqlRepository(configs.AGGREGATOR_URL, configs.NOTIFICATION_WS_URL);
    this.notificationRepository = new GraphqlRepository(configs.NOTIFICATION_URL);
    if (this.token) {
      this.gqlRepository.setToken(this.token);
      this.notificationRepository.setToken(this.token);
    }
  }

  public async startView() {
    hideAll();
    show("poll-container");
    if (this.activeView === "read") {
      hide("poll-back-button");
    } else {
      show("poll-back-button");
    }

    document.getElementById("presentation-title").innerText = "";
    document.getElementById("poll-back-button").onclick = async () => {
      const parameters = new ViewParameters(
        this.token,
        this.sessionUid,
        this.pollUid,
        this.action,
        this.closeIn,
        this.showRealtimeAnswersChecked,
        this.activeView,
        this.currentSlide,
        this.presentationTitle,
        this.presentationSubtitle,
        this.showQRCode,
        this.qrCodeUrl,
        this.countdownEnabled,
      );
      await this.destroy();
      const setupController = new SetupController(parameters);
      setupController.startView();
    };

    if (!isTokenValid(this.token)) {
      this.emptyStateView();
      return;
    }

    if (!this.pollUid) {
      this.emptyStateView();
      return;
    }

    const response = await this.gqlRepository.query(POLL_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      filter: {
        uid: this.pollUid,
      },
    });
    if (!response.data) {
      console.error("Graphql query to fetch poll failed", response.error);
      return;
    }

    const polls = (response.data as { poll: Poll[] }).poll;
    if (!polls || polls.length === 0) {
      this.emptyStateView();
      document.getElementById("presentation-title").innerText = "The poll url is invalid or the poll does not exist";
      return;
    }

    this.poll = Poll.hydrate(polls[0]);

    const qrCodeElement = document.getElementById("qrcode");
    qrCodeElement.innerHTML = "";
    if (this.showQRCode && this.qrCodeSrc) {
      document.getElementById("qrcode").style.display = "inline-block";
      // @ts-ignore
      new QRCode(qrCodeElement, {
        text: this.qrCodeSrc,
        // @ts-ignore
        correctLevel: QRCode.CorrectLevel.L,
      });
    } else {
      hide("qrcode");
    }

    if (this.poll.community.landingCSSConfig) {
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(this.poll.community.landingCSSConfig));
      document.head.appendChild(style);
    }

    (document.getElementById("community-logo") as HTMLImageElement).src = this.getImageSrc();
    (document.getElementById("community-logo") as HTMLImageElement).style.display = "block";
    document.getElementById("poll-title").innerText = this.poll.title;

    const pollContainerElement = document.getElementById("poll-container");
    pollContainerElement.setAttribute("data-poll", this.poll.uid);
    pollContainerElement.setAttribute("data-session", this.poll.session.uid);
    pollContainerElement.setAttribute("data-action", this.action);

    if (this.activeView === "read") {
      await this.storeAutoResetPollFeature();
      await this.executeActionOnPoll();
      this.listenToPollAnswers();
    }
    this.updateTotalVotes();
    this.updateTimeLeft();
    this.updateChoices();

    this.timer = setInterval(() => {
      this.now = DateTimeHelper.getCurrentDateTime();
      this.updateTimeLeft();
      if (this.pollIsClosed) {
        this.updateChoices();
        clearInterval(this.timer);
      }
    }, 950) as unknown as number;
  }

  public async destroy(): Promise<void> {
    try {
      this.closePoll();
    } catch (error) {
      console.error("Error closing poll", error);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.poll = undefined;
  }

  private emptyStateView() {
    document.getElementById("poll-title").innerText = "";
    document.getElementById("presentation-title").innerText = "";
    document.getElementById("qrcode").innerHTML = "";
    document.getElementById("poll-votes-count").innerText = "";
    document.getElementById("poll-voting-status").innerText = "";
    hide("poll-votes-delimiter");
    hide("live-poll-choices");
    hide("community-logo");
    hide("qrcode");
  }

  private showAnswers(closed: boolean): boolean {
    if (closed || this.pollIsClosed) {
      return true;
    }
    return !!this.showRealtimeAnswersChecked;
  }

  private get timeLeft(): string {
    const today = DateTimeHelper.getCurrentDateTime();

    let localEndTime!: Date;
    if (this.poll && this.poll.endTime) {
      localEndTime = DateTimeHelper.toLocal(new Date(this.poll.endTime));
    }
    let result = "";
    if (this.activeView === "edit") {
      result = "Preview mode";
    } else if (this.pollIsClosed) {
      result = "Voting is closed";
    } else if (
      this.poll &&
      (!this.poll.endTime || (this.poll.endTime && DateTimeHelper.differenceInDays(today, localEndTime) > 0))
    ) {
      result = "Voting is open";
    } else if (this.poll && this.poll.endTime) {
      if (localEndTime >= today) {
        let daysDifference = DateTimeHelper.differenceInDays(today, localEndTime);
        daysDifference = daysDifference < 0 ? -daysDifference : daysDifference;
        let hoursDifference = DateTimeHelper.differenceInHours(today, localEndTime);
        hoursDifference = hoursDifference < 0 ? -hoursDifference : hoursDifference;
        let minutesDifference = DateTimeHelper.differenceInMinutes(today, localEndTime);
        minutesDifference = minutesDifference < 0 ? -minutesDifference : minutesDifference;
        let secondsDifference = DateTimeHelper.differenceInSeconds(today, localEndTime);
        secondsDifference = secondsDifference < 0 ? -secondsDifference : secondsDifference;

        if (daysDifference >= 1) {
          result = `${daysDifference} d left`;
        } else if (hoursDifference <= 24 && hoursDifference >= 1) {
          result = `${hoursDifference} h left`;
        } else if (minutesDifference >= 1) {
          result = `${minutesDifference} min left`;
        } else {
          result = `${secondsDifference} s left`;
        }
      }
    }

    return result;
  }

  private get pollIsClosed(): boolean {
    const now = this.now;

    const startTime = this.poll && this.poll.startTime ? new Date(`${this.poll.startTime}Z`) : null;
    if (this.poll.endTime) {
      const endTime = new Date(`${this.poll.endTime}Z`);
      return now > endTime;
    }
    return !startTime || now < startTime;
  }

  private getPollAnswersCount(): number {
    if (this.poll && this.poll.pollAnswers && this.poll.pollAnswers.length) {
      return this.poll.pollAnswers.reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return 0;
  }

  private updateTotalVotes(): void {
    document.getElementById("poll-votes-count").innerText = `Total votes: ${this.getPollAnswersCount()}`;
    show("poll-votes-delimiter");
  }

  private updateTimeLeft(): void {
    document.getElementById("poll-voting-status").innerText = `${this.timeLeft}`;
  }

  private updateChoices(closed: boolean = false) {
    const choiceTemplateElement = document.getElementById("live-poll-choice");

    // remove all children
    show("live-poll-choices");
    document.getElementById("live-poll-choices").innerHTML = "";

    this.poll?.pollAnswers.forEach((answer) => {
      const answerPercentage = this.getAnswerPercentage(answer);
      const choiceElement = choiceTemplateElement.cloneNode(true) as HTMLElement;
      const choiceTitleElement = choiceElement.getElementsByClassName("live-poll-choice-title")[0] as HTMLElement;
      const progressBar = choiceElement.getElementsByClassName("progress-bar")[0] as HTMLElement;
      const iconCheck = choiceElement.getElementsByClassName("live-poll-icon-check")[0] as HTMLElement;

      const showAnswers = this.showAnswers(closed);
      choiceElement.style.display = "flex";
      (choiceElement.getElementsByClassName("live-poll-choice-percentage")[0] as HTMLElement).innerText = showAnswers
        ? `${answerPercentage}%`
        : "";
      choiceTitleElement.innerText = answer.title;
      progressBar.style.width = showAnswers ? `${answerPercentage}%` : "0%";
      if ((closed || this.pollIsClosed) && answer.correctAnswer) {
        iconCheck.style.display = "inline-block";
        choiceTitleElement.style.color = "var(--color-right-answer-text)";
        progressBar.style.background = `var(--color-progress-bar-right-answer)`;
      } else {
        iconCheck.style.display = "none";
        choiceTitleElement.style.color = "#323130";
        progressBar.style.background = "var(--color-progress-bar-vote)";
      }
      document.getElementById("live-poll-choices").appendChild(choiceElement);
    });
  }

  private getUserAnswerCount(pollAnswer: PollAnswer): number {
    if (this.poll?.answerCountByAnswerId && this.poll?.answerCountByAnswerId.length) {
      const answerCount = this.poll.answerCountByAnswerId.find((item) => parseInt(item.key, 10) === pollAnswer.id);
      if (answerCount) {
        return answerCount.value ? parseInt(answerCount.value, 10) : 0;
      }
    }
    return 0;
  }

  private getAnswerPercentage(pollAnswer: PollAnswer): number {
    const pollAnswerCount = this.getUserAnswerCount(pollAnswer);
    return pollAnswerCount > 0 ? parseInt(((100 * pollAnswerCount) / this.getPollAnswersCount()).toString(), 10) : 0;
  }

  private get qrCodeSrc(): string {
    return this.qrCodeUrl.replace("%sessionUid%", this.poll ? this.poll.session.uid : "");
  }
  private getImageSrc(): string {
    if (this.poll?.community && this.poll?.community.logoFileResource) {
      return FileResourceHelper.getFullPath(this.poll.community.logoFileResource, "w250");
    }
    return "";
  }

  private listenToPollAnswers() {
    const notificationSubscription = this.gqlRepository.subscription({
      gqlSubscription: NOTIFICATION_EVENTS_SUBSCRIPTION,
      variables: {
        _locale: "en_US",
        token: this.token,
      },
    });
    notificationSubscription.subscribe(() => {});
    if (this.poll) {
      const genericNotificationSubscription = this.gqlRepository.subscription({
        gqlSubscription: GENERIC_NOTIFICATION_EVENTS_SUBSCRIPTION,
        variables: {
          _locale: "en_US",
          token: this.token,
          channel: `poll-channel-${this.poll.session.uid}`,
        },
      });
      genericNotificationSubscription.subscribe(
        (result: OperationResult<{ genericNotificationEvents: GenericEvent }, object>) => {
          if (!result?.data?.genericNotificationEvents) {
            return;
          }
          const event = result.data.genericNotificationEvents;
          if (event.type === "POLL_ANSWER") {
            const extraData = JSON.parse(event.extra);
            if (extraData) {
              const pollAnswer = extraData.pollAnswer ? PollAnswer.hydrate(extraData.pollAnswer) : null;
              const { pollUid } = extraData;
              if (pollUid === this.pollUid) {
                const oldPollAnswer = extraData.oldPollAnswer ? PollAnswer.hydrate(extraData.oldPollAnswer) : null;
                this.answerUpdate({ pollAnswer, oldPollAnswer });
              }
            }
          } else if (event.type === "POLL_CLOSE" && this.poll && event.entityId === this.poll.uid) {
            const endTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
            this.poll.endTime = format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
            this.poll.endTimestamp = getUnixTime(endTime);
            this.updateChoices(true);
            this.updateTotalVotes();
          }
        }
      );
    }
  }

  answerUpdate({
    pollAnswer,
    oldPollAnswer,
  }: {
    pollAnswer: PollAnswer;
    oldPollAnswer: PollAnswer | null;
  }): void {
    if (!this.poll) {
      return;
    }
    const localPoll = this.poll;
    if (oldPollAnswer) {
      if (localPoll.pollAnswers) {
        const oldLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === oldPollAnswer.uid);
        if (oldLocalPollAnswer && oldLocalPollAnswer.answerCount && oldLocalPollAnswer.answerCount > 0) {
          oldLocalPollAnswer.answerCount -= 1;
        }
      }
      if (localPoll.answerCountByAnswerId) {
        const oldLocalAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId.findIndex(
          (acbId) => acbId.key === `${oldPollAnswer.id}`
        );
        if (oldLocalAnswerCountByAnswerIndex > -1) {
          const value = parseInt(localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value || "0", 10);
          if (value > 1) {
            localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value = `${value - 1}`;
          } else {
            localPoll.answerCountByAnswerId.splice(oldLocalAnswerCountByAnswerIndex, 1);
          }
        }
      }
    }
    const { pollAnswers } = this.poll;
    const localPollAnswerIndex = pollAnswers ? pollAnswers.findIndex((p) => p.uid === pollAnswer.uid) : -1;

    if (localPollAnswerIndex >= 0) {
      const localPollAnswer = pollAnswers && pollAnswers.length ? pollAnswers[localPollAnswerIndex] : null;
      if (localPollAnswer) {
        localPollAnswer.answerCount = localPollAnswer.answerCount ? localPollAnswer.answerCount + 1 : 1;
        if (localPoll.answerCountByAnswerId && localPoll.answerCountByAnswerId.length) {
          const answerCountsIndex = localPoll.answerCountByAnswerId
            ? localPoll.answerCountByAnswerId.findIndex(
                (p) => p.key === (pollAnswer && pollAnswer.id ? pollAnswer.id.toString() : "")
              )
            : -1;
          if (answerCountsIndex >= 0) {
            const { answerCountByAnswerId } = this.poll;
            if (answerCountByAnswerId && answerCountByAnswerId.length) {
              const { value } = answerCountByAnswerId[answerCountsIndex];
              answerCountByAnswerId[answerCountsIndex].value = (parseInt(value || "0", 10) + 1).toString();
            }
          } else if (pollAnswer && pollAnswer.id) {
            localPoll.answerCountByAnswerId.push({
              key: pollAnswer.id.toString(),
              value: "1",
            });
          }
        } else if (pollAnswer && pollAnswer.id) {
          localPoll.answerCountByAnswerId = [
            {
              key: pollAnswer.id.toString(),
              value: "1",
            },
          ];
        }
      }
    }
    this.updateChoices();
    this.updateTotalVotes();
  }

  private async executeActionOnPoll() {
    if (!this.poll) {
      return;
    }

    if (this.action === "open" && this.pollIsClosed) {
      const success = await this.openPoll();
      if (success) {
        this.triggerGenericEvent({
          channels: [`poll-channel-${this.poll.session?.uid}`],
          type: NotificationEventType.POLL_PUBLISH,
          entityId: this.poll.uid,
          extra: "{}",
        });
        if (this.countdownEnabled && this.closeIn && this.poll.session) {
          const closeInDate = this.convertCloseInToDate();
          if (closeInDate) {
            const endDate = closeInDate.getTime() - DateTimeHelper.getCurrentDateTime().getTime();
            const sessionUid = this.poll.session.uid;
            const pollUidToClose = this.poll.uid;
            setTimeout(() => {
              if (this.poll) {
                this.poll.endTime = DateTimeHelper.toISO(DateTimeHelper.getCurrentDateTime());
              }

              this.triggerGenericEvent({
                channels: [`poll-channel-${sessionUid}`],
                type: NotificationEventType.POLL_CLOSE,
                entityId: pollUidToClose,
                extra: "",
              });
            }, endDate);
          }
        }
      }
    }

    if (this.action === "close") {
      console.log("Closing poll on executeActionOnPoll");
      this.closePoll();
      this.triggerGenericEvent({
        channels: [`poll-channel-${this.poll.session?.uid}`],
        type: NotificationEventType.POLL_CLOSE,
        entityId: this.poll.uid,
        extra: "",
      });
    }
  }
  private async triggerGenericEvent(event: { entityId: string; type: string; extra: string; channels: string[] }) {
    const response = await this.notificationRepository.mutate(TriggerNotificationGenericEvent, event);
    return !!response.data;
  }

  private async openPoll(): Promise<boolean> {
    if (!this.poll) {
      return;
    }
    const now = this.now;
    const newEndTime = this.convertCloseInToDate();
    const updatedPoll = {
      uid: this.poll.uid,
      startTime: DateTimeHelper.toISO(now),
      endTime: newEndTime ? DateTimeHelper.toISO(newEndTime) : null,
    };

    const isAutoResetFeatureEnabled = sessionStorage.getItem("auto-reset-poll-feature") === "1";
    const success = await this.publishPoll(updatedPoll, isAutoResetFeatureEnabled);
    if (success) {
      this.poll.startTime = updatedPoll.startTime;
      this.poll.endTime = updatedPoll.endTime;
    }
    return success;
  }

  private convertCloseInToDate(): Date | null {
    let newEndTime = DateTimeHelper.getCurrentDateTime();
    if (this.countdownEnabled && this.closeIn) {
      const unit = this.closeIn.charAt(this.closeIn.length - 1);
      const timeOperationPerUnitMap: Record<string, (date: number | Date, amount: number) => Date> = {
        s: DateTimeHelper.addSeconds,
        m: DateTimeHelper.addMinutes,
        h: DateTimeHelper.addHours,
        d: DateTimeHelper.addDays,
      };
      if (Object.keys(timeOperationPerUnitMap).includes(unit)) {
        const timeToAdd = Number.parseInt(this.closeIn.substring(0, this.closeIn.length - 1), 10);
        newEndTime = timeOperationPerUnitMap[unit](newEndTime, timeToAdd);
      }
    } else {
      newEndTime = null;
    }
    return newEndTime;
  }

  private async closePoll(): Promise<boolean> {
    if (!this.poll) {
      return;
    }
    const now = DateTimeHelper.getCurrentDateTime();
    const updatedPoll = {
      uid: this.poll.uid,
      endTime: DateTimeHelper.toISO(now),
    };
    this.poll.endTime = DateTimeHelper.toISO(now);
    return this.updatePoll(updatedPoll);
  }

  private async updatePoll(poll: Partial<Poll>): Promise<boolean> {
    const result = await this.gqlRepository.mutate(POLL_UPDATE_MUTATION, { entity: poll });
    return !!result.data;
  }

  private async publishPoll(poll: Partial<Poll>, allowNewVersion: boolean): Promise<boolean> {
    const result = await this.gqlRepository.mutate(POLL_PUBLISH_MUTATION, {
      uid: poll.uid,
      startTime: poll.startTime,
      endTime: poll.endTime,
      allowNewVersion,
      realTimeAnswers: this.poll.realTimeAnswers,
    });

    const data = (result.data as { publishPoll: Poll | undefined | null }).publishPoll;
    if (data) {
      this.poll = Poll.hydrate(data);
    }

    return !!data;
  }

  private async storeAutoResetPollFeature(): Promise<void> {
    if (!this.poll?.community) {
      return;
    }

    const feature = await this.gqlRepository.query(AUTO_RESET_FEATURE_QUERY, {
      _locale: "en_US",
      _cachable: false,
      code: this.poll.community.code,
    });
    const data = (feature.data as { communityFeature: Base[] }).communityFeature;
    sessionStorage.setItem("auto-reset-poll-feature", `${data.length ? 1 : 0}`);
  }
}
