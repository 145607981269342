import { ActiveView } from "../../models/activeView";

export default class ViewParameters {
  token: string;
  sessionUid: string;
  pollUid?: string;
  action?: string;
  closeIn?: string;
  showLiveResults: boolean;
  activeView: ActiveView;
  slideNum: number;
  presentationTitle: string;
  presentationSubtitle: string;
  showQRCode: boolean;
  qrCodeUrl?: string;
  countdownEnabled: boolean;

  constructor(
    token: string,
    sessionUid: string,
    pollUid: string | undefined,
    action: string | undefined,
    closeIn: string | undefined,
    showLiveResults: boolean,
    activeView: ActiveView,
    slideNum: number,
    presentationTitle: string,
    presentationSubtitle: string,
    showQRCode: boolean,
    qrCodeUrl: string | undefined,
    countdownEnabled: boolean,
  ) {
    this.token = token;
    this.sessionUid = sessionUid;

    this.pollUid = pollUid;
    this.action = action;
    this.closeIn = closeIn;
    this.showLiveResults = showLiveResults;
    this.activeView = activeView;
    this.slideNum = slideNum;
    this.presentationTitle = presentationTitle;
    this.presentationSubtitle = presentationSubtitle;
    this.showQRCode = showQRCode;
    this.qrCodeUrl = qrCodeUrl;
    this.countdownEnabled = countdownEnabled;
  }


  public get closeInValue(): string | undefined {
    return this.closeIn !== undefined && this.closeIn.length
      ? this.closeIn.slice(0, this.closeIn.length - 1)
      : undefined;
  }

  public get closeInUnit(): string | undefined {
    return this.closeIn !== undefined && this.closeIn.length ? this.closeIn.slice(this.closeIn.length - 1) : undefined;
  }
}
