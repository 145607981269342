import { decodeToken, getLocalStorageKey, hide, hideAll, isTokenValid, show } from "../scripts/utils";
import PollController from "./pollController";
import POLL_GET_QUERY from "../graphql/pollGet";
import SESSION_GET_QUERY from "../graphql/sessionGet";
import Poll from "../models/poll";
import GraphqlRepository from "../scripts/graphqlRepository";
import configs from "../scripts/configs";
import { ActiveView } from "../models/activeView";
import POLL_RESET from "../graphql/pollReset";
import ViewParameters from "../scripts/types/viewParameters";
import Session from "../models/session";
import PowerpointHelper from "../scripts/powerpointHelper";
import COMMUNITY_GET_QUERY from "../graphql/communityGet";
import Community from "../models/community";
import NotificationEventType from "../models/notificationEventType";
import TriggerNotificationGenericEvent from "../graphql/triggerNotificationGenericEvent";

export default class SetupController {
  private token: string;
  private sessionUid: string;
  private pollUid: string;
  private slideNum: number;
  private activeView: ActiveView;
  private action: string = "open";
  private closeInValue: string | undefined;
  private closeInUnit: string | undefined;
  private showResults: boolean;
  private presentationTitle: string;
  private presentationSubtitle: string;
  private showQRCode: boolean;
  private qrCodeUrl: string;

  private community: Community | undefined;
  private poll: Poll | undefined;
  private session: Session | undefined;
  private gqlRepository: GraphqlRepository;
  private activeDebouncer: number | undefined;
  private currentResetPollStep: number;

  private notificationRepository: GraphqlRepository;

  private countdownEnabled: boolean = false;

  constructor(viewParameters: ViewParameters) {
    this.initData(viewParameters);
  }

  private initData(viewParameters: ViewParameters) {
    const {
      token,
      sessionUid,
      pollUid,
      action,
      closeIn,
      showLiveResults,
      activeView,
      slideNum,
      presentationTitle,
      presentationSubtitle,
      showQRCode,
      qrCodeUrl,
      closeInUnit,
      closeInValue,
      countdownEnabled,
    } = viewParameters;
    this.token = token;
    this.sessionUid = sessionUid;
    this.pollUid = pollUid;
    this.action = action || "open";
    this.closeInUnit = closeIn !== undefined ? closeInUnit : undefined;
    this.closeInValue = closeIn !== undefined ? closeInValue : undefined;
    this.countdownEnabled = !!countdownEnabled;
    this.showResults = showLiveResults;
    this.activeView = activeView;
    this.slideNum = slideNum;
    this.presentationTitle = presentationTitle;
    this.presentationSubtitle = presentationSubtitle;
    this.showQRCode = showQRCode;
    this.qrCodeUrl = qrCodeUrl;
    this.gqlRepository = new GraphqlRepository(configs.AGGREGATOR_URL);
    this.notificationRepository = new GraphqlRepository(configs.NOTIFICATION_URL);
    if (this.token) {
      this.gqlRepository.setToken(this.token);
      this.notificationRepository.setToken(this.token);
    }
    this.currentResetPollStep = 0;
  }

  async startView(): Promise<void> {
    hideAll();
    show("poll-form");

    this.initView();
    await this.loadCommunity();
    await this.loadSession();
    this.registerHandlers();
    await this.loadPoll();
    await this.validateView();
  }

  public destroy(): void {}

  private initView(): void {
    if (this.token) {
      show("configuration-form");
      hide("addin-description");
    } else {
      hide("configuration-form");
      show("addin-description", "inline");
    }

    this.toggleCloseInAndShowRealtime();
    const countdownCheckbox = document.getElementById("countdown-checkbox") as HTMLInputElement;
    countdownCheckbox.checked = this.countdownEnabled;

    if (this.action === "close") {
      hide("duration-section");
    } else {
      show("duration-section");
    }
  }

  private registerHandlers(): void {
    this.tokenInputHandler();
    this.sessionUidInputHandler();
    this.pollDropdownHandler();
    this.actionDropdownHandler();
    this.durationDropdownHandler();
    this.showQRCodeCheckboxHandler();
    this.previewButtonHandler();
    this.resetPollButtonHandler();
    this.countdownCheckboxHandler();
  }

  private tokenInputHandler(): void {
    this.validateToken();
    (document.getElementById("token-input") as HTMLInputElement).value = this.token || "";
    (document.getElementById("token-input") as HTMLInputElement).oninput = async (e: Event) => {
      this.token = e.target.value;

      const isValid = this.validateToken();
      if (isValid) {
        this.gqlRepository.setToken(this.token);
        this.notificationRepository.setToken(this.token);
        await this.loadCommunity();
        hide("addin-description");
      } else {
        show("addin-description", "inline");
      }

      this.save();
    };
  }

  private sessionUidInputHandler(): void {
    document.getElementById("session-name").innerText = this.session?.name || "";
    (document.getElementById("session-uid-input") as HTMLInputElement).value = this.sessionUid || "";
    (document.getElementById("session-uid-input") as HTMLInputElement).oninput = async (e: Event) => {
      this.sessionUid = e.target.value;

      if (!this.activeDebouncer) {
        this.activeDebouncer = setTimeout(async () => {
          await this.loadSession();
          this.populatePollsDropdown();
          await this.loadPoll();
          await this.validateView();

          document.getElementById("session-name").innerText = this.session?.name || "";

          this.save();
          this.activeDebouncer = undefined;
        }, 400) as unknown as number;
      }
    };
  }

  private pollDropdownHandler(): void {
    const pollsDropdown = document.getElementById("poll-dropdown") as HTMLSelectElement;
    if (!pollsDropdown) {
      return;
    }

    this.populatePollsDropdown();
    pollsDropdown.onchange = async (e) => {
      this.pollUid = e.target.value;
      this.save();
      await this.loadPoll();
      this.validateView();
    };
  }

  private populatePollsDropdown(): void {
    const pollsDropdown = document.getElementById("poll-dropdown") as HTMLSelectElement;
    if (!pollsDropdown) {
      return;
    }

    // clear all options
    pollsDropdown.innerHTML = "";
    if (this.session?.polls.length > 0) {
      this.session.polls.forEach((poll: Poll) => {
        const optionElement = document.createElement("option");
        optionElement.value = poll.uid;
        optionElement.innerText = poll.title;
        pollsDropdown.appendChild(optionElement);
      });
      if (this.pollUid) {
        const foundPoll = this.session.polls.find((poll) => poll.uid === this.pollUid);
        if (!foundPoll) {
          this.pollUid = this.session.polls[0].uid;
        }
      } else {
        this.pollUid = this.session.polls[0].uid;
      }
    } else {
      this.pollUid = "";
    }

    pollsDropdown.value = this.pollUid || "";
  }

  private actionDropdownHandler(): void {
    const actionDropdown = document.getElementById("action-dropdown") as HTMLSelectElement;
    if (!actionDropdown) {
      return;
    }
    actionDropdown.value = this.action || "open";
    actionDropdown.onchange = (e) => {
      this.action = e.target.value;
      this.toggleCloseInAndShowRealtime();
      this.save();

      if (this.action === "close") {
        hide("duration-section");
      } else {
        show("duration-section");
      }

      this.validateView();
    };
  }

  private durationDropdownHandler(): void {
    const durationInput = document.getElementById("duration-input") as HTMLInputElement;
    const durationUnitDropdown = document.getElementById("duration-unit-dropdown") as HTMLSelectElement;
    if (!durationUnitDropdown) {
      return;
    }

    durationInput.value = this.closeInValue;
    durationUnitDropdown.value = this.closeInUnit;

    durationInput.oninput = (e: Event) => {
      this.closeInValue = e.target.value;
      this.save();
    };
    durationUnitDropdown.onchange = (e) => {
      this.closeInUnit = e.target.value;
      this.save();
    };
  }

  private showQRCodeCheckboxHandler(): void {
    const showQRCodeCheckbox = document.getElementById("show-qrcode-checkbox") as HTMLInputElement;

    if (!showQRCodeCheckbox) {
      return;
    }
    showQRCodeCheckbox.checked = this.showQRCode;

    showQRCodeCheckbox.onchange = (e) => {
      this.showQRCode = (e.target as HTMLInputElement).checked;
      this.save();
    };
  }

  private previewButtonHandler(): void {
    document.getElementById("poll-preview").onclick = () => {
      this.destroy();
      const parameters = new ViewParameters(
        this.token,
        this.sessionUid,
        this.pollUid,
        this.action,
        this.closeInValue && this.closeInUnit ? `${this.closeInValue}${this.closeInUnit}` : undefined,
        this.showResults,
        this.activeView,
        this.slideNum,
        this.presentationTitle,
        this.presentationSubtitle,
        this.showQRCode,
        this.qrCodeUrl,
        this.countdownEnabled,
      );
      const pollView = new PollController(parameters);
      pollView.startView();
    };
  }

  private resetPollButtonHandler(): void {
    document.getElementById("btn-poll-reset").onclick = async () => {
      this.currentResetPollStep = 1;
      this.validateView();
    };
    document.getElementById("btn-poll-reset-confirm-no").onclick = async () => {
      this.currentResetPollStep = 0;
      this.validateView();
    };
    document.getElementById("btn-poll-reset-confirm-yes").onclick = async () => {
      const result = await this.gqlRepository.mutate(POLL_RESET, {
        pollUid: this.poll.uid,
      });

      const data = (result.data as { resetPoll: Poll | undefined | null }).resetPoll;
      if (data) {
        this.triggerGenericEvent({
          channels: [`poll-channel-${this.sessionUid}`],
          type: NotificationEventType.POLL_RESET,
          entityId: this.pollUid,
          extra: "",
        });

        this.poll = Poll.hydrate(data);
        this.validateView();
        show("poll-url-info");
        document.getElementById("poll-url-info").innerText = "Poll is reset";
        setTimeout(() => {
          document.getElementById("poll-url-info").innerText = "";
          hide("poll-url-info");
        }, 4000);
      }
      this.validateView();
    };
  }

  private save(): Promise<void> {
    const payload = new ViewParameters(
      this.token,
      this.sessionUid,
      this.pollUid,
      this.action,
      this.closeInValue && this.closeInUnit ? `${this.closeInValue}${this.closeInUnit}` : undefined,
      this.showResults,
      this.activeView,
      this.slideNum,
      this.presentationTitle,
      this.presentationSubtitle,
      this.showQRCode,
      this.qrCodeUrl,
      this.countdownEnabled,
    );
    return PowerpointHelper.saveSetting(getLocalStorageKey(this.slideNum), JSON.stringify(payload));
  }

  validateToken(): boolean {
    const isValid = isTokenValid(this.token);
    const errorElement = document.getElementById("token-error");
    if (isValid) {
      show("configuration-form");
      errorElement.innerText = "";
    } else {
      hide("configuration-form");
      errorElement.innerText = "This token is invalid";
    }
    return isValid;
  }

  async validateView(): Promise<void> {
    // session validation
    const sessionValidation = await this.validateSession();
    const sessionErrorElement = document.getElementById("session-error");
    // reset validation
    hide("session-error");
    // execute validation
    if (!sessionValidation.isValid) {
      show("session-error", "block");
      sessionErrorElement.innerText = sessionValidation.reason;
    }

    // poll validation
    const pollValidation = await this.validatePoll();
    const pollUrlErrorElement = document.getElementById("poll-url-error");
    // reset validation
    pollUrlErrorElement.style.display = "none";
    document.getElementById("action-error").innerText = "";
    hide("btn-poll-reset");
    hide("btn-poll-reset-confirm-yes");
    hide("btn-poll-reset-confirm-no");
    // execute validation
    if (pollValidation.isValid) {
      this.currentResetPollStep = -1;
    } else {
      pollUrlErrorElement.style.display = "block";
      pollUrlErrorElement.innerText = pollValidation.reason;
    }
    this.toggleCloseInAndShowRealtime();
    if (pollValidation.action === "resetPoll" && this.poll.isPublished) {
      if (this.currentResetPollStep === -1) {
        this.currentResetPollStep = 0;
      }
      if (this.currentResetPollStep === 0) {
        show("btn-poll-reset");
      }
      if (this.currentResetPollStep === 1) {
        pollUrlErrorElement.innerText = "Are you sure? This will delete all the exiting poll's votes";
        show("btn-poll-reset-confirm-yes");
        show("btn-poll-reset-confirm-no");
      }
    }
  }

  private async loadCommunity(): Promise<void> {
    this.community = undefined;
    if (!this.token) {
      return;
    }
    const payload = decodeToken(this.token);
    if (!payload) {
      return;
    }
    const response = await this.gqlRepository.query(COMMUNITY_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      code: payload.c,
    });
    if (!response.data) {
      return;
    }

    const communities = (response.data as { community: Community[] }).community;
    if (!!communities && communities.length > 0) {
      this.community = Community.hydrate(communities[0]);
      this.qrCodeUrl = this.community.pollSessionPathQRCode || "";
    }
  }

  private async loadPoll(): Promise<void> {
    this.poll = undefined;
    if (!this.pollUid) {
      return;
    }
    const response = await this.gqlRepository.query(POLL_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      filter: {
        uid: this.pollUid,
      },
    });
    if (!response.data) {
      return;
    }

    const polls = (response.data as { poll: Poll[] }).poll;
    if (!!polls && polls.length > 0) {
      this.poll = Poll.hydrate(polls[0]);
    }
  }

  private async loadSession() {
    this.session = undefined;
    if (!this.sessionUid) {
      return;
    }
    const response = await this.gqlRepository.query(SESSION_GET_QUERY, {
      _locale: "en_US",
      _cachable: false,
      uid: this.sessionUid,
    });
    if (!response.data) {
      return;
    }

    const sessions = (response.data as { session: Session[] }).session;
    if (!!sessions && sessions.length > 0) {
      this.session = Session.hydrate(sessions[0]);
    }
  }

  async validateSession(): Promise<{ isValid: boolean; reason?: string; action?: string }> {
    if (this.sessionUid && !this.session) {
      return {
        isValid: false,
        reason: "No session matches this uid",
      };
    }
    return {
      isValid: true,
    };
  }

  async validatePoll(): Promise<{ isValid: boolean; reason?: string; action?: string }> {
    if (this.pollUid && !this.poll) {
      return {
        isValid: false,
        reason: "This poll does not exist",
      };
    }

    if (this.poll?.isPublished) {
      return {
        isValid: false,
        reason: "This poll was already used",
        action: "resetPoll",
      };
    }
    return {
      isValid: true,
    };
  }

  toggleCloseInAndShowRealtime(): void {
    if (this.countdownEnabled) {
      show("duration-select");
    } else {
      hide("duration-select");
    }
  }

  private async triggerGenericEvent(event: { entityId: string; type: string; extra: string; channels: string[] }) {
    const response = await this.notificationRepository.mutate(TriggerNotificationGenericEvent, event);
    return !!response.data;
  }

  private countdownCheckboxHandler(): void {
    const countdownCheckbox = document.getElementById("countdown-checkbox") as HTMLInputElement;

    if (!countdownCheckbox) {
      return;
    }

    countdownCheckbox.checked = this.countdownEnabled;

    countdownCheckbox.onchange = (e) => {
      this.countdownEnabled = (e.target as HTMLInputElement).checked;

      if (this.countdownEnabled) {
        show("duration-select");
        this.closeInValue = "20";
        this.closeInUnit = "s";
      } else {
        hide("duration-select");
        this.closeInValue = undefined;
        this.closeInUnit = undefined;
      }

      const durationInput = document.getElementById("duration-input") as HTMLInputElement;
      const durationUnitDropdown = document.getElementById("duration-unit-dropdown") as HTMLSelectElement;
      durationInput.value = this.closeInValue;
      durationUnitDropdown.value = this.closeInUnit;
      this.save();
    };
  }
}
