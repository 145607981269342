export default `subscription GenericNotificationEventsSubscription($token: String!, $channel: String!) {
  genericNotificationEvents(token: $token, channel: $channel) {
    ...subscriptionNotificationGenericEventsFragment
    __typename
  }
}

fragment subscriptionNotificationGenericEventsFragment on GenericNotificationData {
  entityId
  extra
  type
  __typename
}
`;
