export default `subscription NotificationEventsSubscription($token: String!) {
  notificationEvents(token: $token) {
      ...subscriptionNotificationEventsFragment
    __typename
  }
}

fragment subscriptionNotificationEventsFragment on NotificationOutput {
  type
  success
  requestId
  data {
    uid
    action
    createdTime
    createdTimestamp
    triggered {
      __typename
      ... on Survey {
        uid
        code
        title
        instructions
        strategy
        layout
        loginRequired
        editable
        maxAnswers
        startTime
        endTime
        __typename
      }
      ... on CompanyUserRole {
        uid
        state
        moderator
        company {
          uid
          name
          __typename
        }
        role {
          uid
          code
          __typename
        }
        user {
          uid
          __typename
        }
        __typename
      }
      ... on CommunityUserConnection {
        uid
        linkState
        user {
          uid
          firstName
          lastName
          jobTitle
          __typename
        }
        connectedUser {
          uid
          firstName
          lastName
          jobTitle
          __typename
        }
        __typename
      }
      ... on MeetingParticipant {
        uid
        state
        meeting {
          uid
          subject
          isCancelled
          __typename
        }
        __typename
      }
      ... on Meeting {
        uid
        subject
        isCancelled
        participants {
          uid
          state
          user {
            uid
            __typename
          }
          __typename
        }
        __typename
      }
      ... on Comment {
        uid
        textContent
        parentComment {
          uid
          __typename
        }
        __typename
      }
      ... on Session {
        uid
        name
        __typename
      }
      ... on MeetingRequest {
        uid
        schemaCode
        message
        readState
        trash
        timezoneName
        startTime
        endTime
        createdTime
        startTimestamp
        endTimestamp
        createdTimestamp
        exhibitor {
          uid
          name
          logoFileResource {
            schemaCode
            path
            __typename
          }
          __typename
        }
        user {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
            __typename
          }
          __typename
        }
        companyRep {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
            __typename
          }
          __typename
        }
        guests {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
            __typename
          }
          __typename
        }
        meetingRequestMeeting {
          uid
          subject
          timeZoneName
          startTime
          startTimestamp
          endTimestamp
          creatorUser {
            uid
            firstName
            lastName
            pictureFileResource {
              path
              schemaCode
              __typename
            }
            __typename
          }
          participants {
            uid
            state
            user {
              uid
              firstName
              lastName
              pictureFileResource {
                path
                schemaCode
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      ... on CustomNotification {
        uid
        scheduleTime
        scheduleTimestamp
        message
        actionUrl
        actionText
        __typename
      }
    }
    notifiedUsers {
      uid
      __typename
    }
    initiator {
      __typename
      ... on Exhibitor {
        uid
        name
        logoFileResource {
          schemaCode
          path
          __typename
        }
        __typename
      }
      ... on CommunityUser {
        uid
        firstName
        lastName
        pictureFileResource {
          path
          schemaCode
          __typename
        }
        __typename
      }
      ... on Session {
        uid
        name
        __typename
      }
      ... on Meeting {
        uid
        subject
        __typename
      }
      ... on CustomNotification {
        uid
        scheduleTime
        scheduleTimestamp
        message
        actionUrl
        actionText
        __typename
      }
    }
    __typename
  }
  __typename
}
`;
