import configs from "./configs";
import { jwtDecode } from "jwt-decode";
import JwtPayload from "./types/jwtPayload";

const { LOCAL_STORAGE_KEY } = configs;

export function hideAll() {
  hide("poll-form");
  hide("poll-container");
  hide("poll-back-button");
  hide("btn-poll-reset");
  hide("poll-url-info");
}

export function hide(elementId: string) {
  document.getElementById(elementId).style.display = "none";
}
export function show(elementId: string, display: string = "flex") {
  document.getElementById(elementId).style.display = display;
}

export function getLocalStorageKey(currentSlide: number): string {
  return `${LOCAL_STORAGE_KEY}-slide-${currentSlide}`;
}

export function isTokenValid(token: string): boolean {
  if (token) {
    try {
      jwtDecode(token);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export function decodeToken(token: string): JwtPayload | undefined {
  try {
    return jwtDecode(token) as JwtPayload;
  } catch (e) {
    return undefined;
  }
}
