export default `mutation UpdatePoll($entity: _PollInput) {
  PollUpdate(entity: $entity) {
    ...pollFullFragment
  }
}

fragment pollFullFragment on Poll {
  uid
  title
  schemaCode
  startTime
  endTime
  startTimestamp
  endTimestamp
  realTimeAnswers
  community {
    uid
    code
    landingCSSConfig
    votingBufferTime
    logoFileResource {
      path
      schemaCode
      edition {
        urlName
      }
    }
  }
  pollAnswers(orderBy: displaySequence_asc) {
    ...pollAnswerBaseFragment
  }
  session {
    ...sessionBaseFragment
  }
  answerCountByAnswerId {
    key
    value
  }
  _myAnswer(myUid: "") {
    ...pollUserAnswerBaseFragment
  }
}

fragment sessionBaseFragment on Session {
  uid
  schemaCode
  name
  shortName
  description
  descriptionHtml
  startTime
  startTimestamp
  endTime
  endTimestamp
  speakers
  featured
  imageFileResource {
    ...fileResourceBaseFragment
  }
  bannerFileResource {
    ...fileResourceBaseFragment
  }
  _bookmarkCount
  likeCount: _likeCount
  dislikeCount: _dislikeCount
  loveCount: _loveCount
}

fragment fileResourceBaseFragment on FileResource {
  uid
  path
  fileType
  schemaCode
}

fragment pollAnswerBaseFragment on PollAnswer {
  uid
  id
  title
  answerCount
  displaySequence
  correctAnswer
}

fragment pollUserAnswerBaseFragment on PollUserAnswer {
  uid
  user {
    ...communityUserBaseFragment
  }
  pollAnswer {
    ...pollAnswerBaseFragment
  }
}

fragment communityUserBaseFragment on CommunityUser {
  uid
  schemaCode
  firstName
  lastName
  name
  email
  jobTitle
  employerName
  banned
  profilePrivacyMode
  pictureFileResource {
    ...fileResourceBaseFragment
  }
  videoPresentationS3Url
}
`;
