import Base from "./base";
import FileResource from "./fileResource";

class Community extends Base {
  code?: string;
  longName?: string;
  timeZone?: string;
  shortName?: string;
  description?: string;
  pollSessionPathQRCode?: string;
  landingCSSConfig?: string;
  logoFileResource: FileResource;
  votingBufferTime?: number;

  public static hydrate(obj: any): Community {
    const community = new Community();
    Object.assign(community, obj);
    return community;
  }
}

export default Community;
